import React from 'react'
import AllPartners from './AllPartners'

export default function Partner() {
  return (
    <div>
        <AllPartners />
    </div>
  )
}
