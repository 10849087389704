import React from 'react'
import FormDon from './FormDon'

export default function Don() {
  return (
    <div>
        <FormDon />
    </div>
  )
}
