import React from 'react'
import BarOne from './BarOne'
import BarTwo from './BarTwo'

export default function About() {
  return (
    <div>
      <BarOne/>
      <BarTwo/>
    </div>
  )
}
