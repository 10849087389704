import React from 'react'
import ProjetAll from './ProjetAll'

export default function Projet() {
  return (
    <div>
        <ProjetAll/>
    </div>
  )
}
