import React from 'react'
import Carrousel from '../pages/Accueil/Carrousel'
import Navbar from './Navbar'
import Accueil from '../pages/Accueil/Accueil'
import Footer from './Footer'
import About from '../pages/aboutsScreen/About'

export default function Homepage() {
  return (
    <div>
        <Accueil/>
    </div>
  )
}
